import TopBtn from '@raylink-overseas/common/components/top-btn'
import React from 'react'
import { Layout } from '@raylink-overseas/common/components'
import { BusinessPricingWrapper, ContentWarpper, PirceTitle } from '../atoms'
import PriceCard from '../components/price-card'
import CreditCard from '../components/credit-card'
import Service from '../components/service'
import ComparePlans from '../components/compare-plans'
import Question from '../components/question'
import PriceTabs, { userTypes } from '../components/price-tabs'

export type UserType = {
  title: string
  tabDescription: string
}

export default function PricingPage() {
  return (
    <Layout pageType="businessPrice">
      <BusinessPricingWrapper className="business">
        <div className="half-wrapper">
          <ContentWarpper>
            <PirceTitle>
              Pick Your Plan of Avica <span>Business</span>
            </PirceTitle>
            <PriceTabs type={Object.keys(userTypes)[1]} />
            <PriceCard isPersonalMode={false} />
            <CreditCard />
          </ContentWarpper>
        </div>
        <ContentWarpper>
          <Service type={Object.keys(userTypes)[1]} />
        </ContentWarpper>
        <ComparePlans isPersonalMode={false} />
        <ContentWarpper>
          <Question isPersonalMode={false} />
          <TopBtn />
        </ContentWarpper>
      </BusinessPricingWrapper>
    </Layout>
  )
}
